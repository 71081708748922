/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import "@styles/global.css";

import { ShoppingCartProvider } from "@context/ShoppingCartContext/ShoppingCartContext"

export const wrapRootElement = ({ element }) => (
  <ShoppingCartProvider>{element}</ShoppingCartProvider>
)