// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-item-page-item-page-jsx": () => import("/opt/build/repo/src/templates/ItemPage/ItemPage.jsx" /* webpackChunkName: "component---src-templates-item-page-item-page-jsx" */),
  "component---src-templates-blog-post-blog-post-jsx": () => import("/opt/build/repo/src/templates/BlogPost/BlogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-blog-post-jsx" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-careers-jsx": () => import("/opt/build/repo/src/pages/about/careers.jsx" /* webpackChunkName: "component---src-pages-about-careers-jsx" */),
  "component---src-pages-about-contact-us-jsx": () => import("/opt/build/repo/src/pages/about/contact-us.jsx" /* webpackChunkName: "component---src-pages-about-contact-us-jsx" */),
  "component---src-pages-about-index-jsx": () => import("/opt/build/repo/src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-about-privacy-policy-jsx": () => import("/opt/build/repo/src/pages/about/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-about-privacy-policy-jsx" */),
  "component---src-pages-about-returns-jsx": () => import("/opt/build/repo/src/pages/about/returns.jsx" /* webpackChunkName: "component---src-pages-about-returns-jsx" */),
  "component---src-pages-about-shipping-jsx": () => import("/opt/build/repo/src/pages/about/shipping.jsx" /* webpackChunkName: "component---src-pages-about-shipping-jsx" */),
  "component---src-pages-about-terms-and-conditions-jsx": () => import("/opt/build/repo/src/pages/about/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-about-terms-and-conditions-jsx" */),
  "component---src-pages-blog-jsx": () => import("/opt/build/repo/src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-checkout-jsx": () => import("/opt/build/repo/src/pages/checkout.jsx" /* webpackChunkName: "component---src-pages-checkout-jsx" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-purchase-cancel-jsx": () => import("/opt/build/repo/src/pages/purchase/cancel.jsx" /* webpackChunkName: "component---src-pages-purchase-cancel-jsx" */),
  "component---src-pages-purchase-success-jsx": () => import("/opt/build/repo/src/pages/purchase/success.jsx" /* webpackChunkName: "component---src-pages-purchase-success-jsx" */)
}

